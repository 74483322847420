import styles from "../assets/css/index.module.scss";
import { NextPage } from "next";
import staticData, { assetsBaseURL, buttonOverride, daysOfWeek, extractSelectedOffer, googleSpreadsheetURL, monthsOptions, offers, seasonOptions } from "../models/staticData";
import NBAPageWrapper from "../../../layouts/nba-page-wrapper/views/NBAPageWrapper"
import { useEffect, useState } from "react";
import FutureGame from "./FutureGame";
import PastGame from "./PastGame";
import Filter from "./Filter";
import FilterV2 from "./FilterV2";
import { areAnyGamesCompleted, buildTeamsList, filterData, getSeasonTypeDescription, nbaSeasons, processMonthOfYear, processOffer, rewriteCDNURL, transformSpreadsheetData, useCAPISchedule, useFetchData } from "../components/etc/functions";
import React from "react";
import { EScheduleActionTypes, TlayoutType, TSeasonTypes } from "../../../../etc/types";
import { ScheduleContextProvider, useScheduleContext } from "../components/etc/ScheduleContext";
import { Form } from "react-bootstrap";
import { useSearchParams } from 'next/navigation'
import { useWindowWidth } from "@react-hook/window-size";

//Dynamic NBA Season Year Function
const nbaSeasonsYears = nbaSeasons();
const nbaCurrentSeason = nbaSeasonsYears[0]

const ScheduleWithContext: React.FC = () => {

    return <ScheduleContextProvider>
        <BoilerplateView />
    </ScheduleContextProvider>
}

const BoilerplateView: NextPage = () => {
    //Query Params
    const params = useSearchParams()

    //upcoming or past
    const { state, dispatch } = useScheduleContext()
    const { upcomingOrPast, year, weekDaysChecked, layoutType } = state

    //checking if mobile
    const onlyWidth = useWindowWidth()
    const [isMobile, setIsMobile] = useState(false)

    //Promotions & Tickets Data From Google
    const googleAPIResData = useFetchData(rewriteCDNURL(googleSpreadsheetURL))
    const spreadsheetData = googleAPIResData.slice(1).map(item => transformSpreadsheetData(item))

    //NBA API Data Individual & Merged
    const schedule = useCAPISchedule(year)
    const filteredData = filterData(schedule, state)

    //In Results we reverse the filtered Data in order to show past dates first
    !upcomingOrPast ? filteredData.reverse() : null

    //For SeasonType Labels
    let seasonTypeCheck = ""
    //Workaround to delay simultaneous feed fetch (avoid request limit)
    let fetchDelay = 0
    //
    useEffect(() => {
        dispatch({ type: EScheduleActionTypes.set_google_spreadsheet_data, value: spreadsheetData })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [spreadsheetData.length, dispatch])

    //For Filter V2
    useEffect(() => {
        if (schedule.length) {
            const teamsFullList = buildTeamsList(schedule, "", true)
            dispatch({ type: EScheduleActionTypes.set_team_list, value: teamsFullList })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [schedule.length, dispatch])

    //Query Params Only
    // The purpose of this effect is to only initialize filter widget using state.
    useEffect(() => {
        for (const [key, value] of params.entries()) {
            switch (key) {
                // case "is_home":
                //     dispatch({
                //         type: EScheduleActionTypes.set_home_or_away_games,
                //         value: 1 === +value ? "home" : "away"
                //     })
                //     break
                case "season_type":
                    const isFound = seasonOptions.find(s => value === s[0])
                    if (isFound) {
                        dispatch({
                            type: EScheduleActionTypes.set_seasontype,
                            value: value as TSeasonTypes
                        })
                    }
                    break
                case "opponent":
                    dispatch(
                        {
                            type: EScheduleActionTypes.selected_team,
                            value: value
                        })
                    break
                case "day":
                    daysOfWeek.map((item) => {
                        const items = new Map(value.split(",").map(d =>
                            [+d, +d]
                        ))
                        if (items.has(item.key)) {
                            dispatch({
                                type: EScheduleActionTypes.save_week_days,
                                value: items
                            })
                        }
                    })
                    break
                case "month":
                    monthsOptions.map((item) => {
                        const items = new Map(value.split(",").map(d =>
                            [+d, +d]
                        ))
                        if (items.has(item.key)) {
                            dispatch({
                                type: EScheduleActionTypes.save_months,
                                value: items
                            })
                        }
                    })
                    break
                case "promotion":
                    offers.map((item) => {
                        const items = new Map(value.split(",").map(d =>
                            [+d, +d]
                        ))
                        if (items.has(item.key)) {
                            dispatch({
                                type: EScheduleActionTypes.set_offers,
                                value: items
                            })
                        }
                    })
                    break
                //Hidden Date Range Setting - not reflecting in the UI
                case "date_range":
                    dispatch(
                        {
                            type: EScheduleActionTypes.set_date_range,
                            value: value
                        })
                    break
            }
        }
    }, [schedule.length, dispatch, params])

    //UseFetch Filtered Data
    // Required for the users interaction with the filter widget.
    useEffect(() => {
        dispatch({ type: EScheduleActionTypes.set_filtered_schedule_data, value: filteredData })
        //We do not want to infinetly loop by adding filteredData
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filteredData.length, dispatch])

    //For the season year dropdown we are modifiying the dash for the slash per Marketing Consistency
    const seasonTitle = schedule.length
        ? schedule[0].seasonYear.replace("-", "/")
        : year + "/" + String(year + 1).slice(2)

    //checking to see if its mobile
    useEffect(() => {
        setIsMobile(1200 >= onlyWidth)
    }, [onlyWidth])

    //Checking if it is /Schedule or /Single Game Tickets
    useEffect(() => {
        const layoutType: TlayoutType =
            window.location.pathname === '/schedule' || window.location.pathname === "/grizzlies/schedule"
                ? 'schedule'
                : 'single-game-tickets'
        dispatch({
            type: EScheduleActionTypes.set_layout_type,
            value: layoutType
        })

        if (layoutType === 'single-game-tickets') {
            dispatch({
                type: EScheduleActionTypes.set_home_or_away_games,
                value: "home"
            })
        }
    }, [dispatch])

    return (
        <NBAPageWrapper {...staticData}>
            <main className={styles.boilerplate}>
                {layoutType === 'schedule' ?
                    <section className="mainBanner">
                        <div className="sch_selector">
                            <h1> Schedule </h1>
                            {/* NBA Season Dropdown */}
                            <Form className="seasonYearsSelector" >
                                <Form.Select name="seasons" id="szns-select" value={year}
                                    onChange={(e) => {
                                        const yr = Number(e.target.value)
                                        dispatch({
                                            type: EScheduleActionTypes.set_year,
                                            value: yr
                                        })
                                    }}
                                >
                                    {nbaSeasonsYears.map((i) =>
                                        <option key={i} value={i}> {i + "/" + (i + 1)} </option>
                                    )}
                                </Form.Select>
                            </Form>
                        </div>

                        {/* Page Presenter Logo (white) Desktop */}
                        <div className="page_sponsor_desktop">
                            <div className="sponsorLogoLink">
                                <div>
                                    <h6> presented by </h6>
                                    <img alt="OrthoSouth logo" src={assetsBaseURL + "orthosouthlogo_v2.svg"}></img>
                                </div>
                            </div>
                        </div>
                    </section>
                    :
                    <section className="mainBanner">
                        <div className="sch_selector">
                            <h1> Single Game Tickets </h1>
                        </div>
                        {/* Page Presenter Logo (white) Desktop */}
                        <div className="page_sponsor_desktop">
                            <div className="sponsorLogoLink">
                                <div>
                                    <h6> presented by </h6>
                                    <img alt="OrthoSouth logo" src={assetsBaseURL + "Ticketmaster-Logo-Dark-RGB.svg"}></img>
                                </div>
                            </div>
                        </div>
                    </section>
                }

                {/* sponsor has been hidden  */}
                {/* <section className="sticky-container">
                    <div className="sticky-section">
                        <a className="sponsorLogoLink" href="" target="_blank" rel="noreferrer">

                            <h6> presented by Orthosouth</h6>
                            <img alt="orthosouth" src={assetsBaseURL + ""}></img>
                        </a>
                    </div>
                </section> */}
                {/* Filter V2 - Launch 8.15.24 */}
                {layoutType === 'single-game-tickets' ? <FilterV2 /> : null}
                {/* Toggle between upcoming and past games. Has conditional so that if the chosen year on dropdown matches the current season we show these options. IF it is a previous legacy year these options will not be showing */}
                <section className="globalContainer filtersContainer">
                    {year === nbaCurrentSeason ?
                        layoutType === 'schedule' ?
                            <div className="upcomingResultsToggle">
                                <button
                                    style={buttonOverride}
                                    onClick={() =>
                                        dispatch({
                                            type: EScheduleActionTypes.show_upcoming_or_past,
                                            value: !upcomingOrPast
                                        })
                                    }

                                    className={upcomingOrPast ? "active" : "inactive"}
                                >upcoming</button>

                                {/* for current season */}
                                {areAnyGamesCompleted(schedule) ?
                                    <button
                                        style={buttonOverride}
                                        onClick={() =>
                                            dispatch({
                                                type: EScheduleActionTypes.show_upcoming_or_past,
                                                value: !upcomingOrPast
                                            })
                                        }
                                        className={!upcomingOrPast ? "active" : "inactive"}
                                    >results</button>

                                    : null
                                }
                            </div>
                            : <h2 className="upcomingResultsToggle"> Find tickets </h2>
                        :
                        <h2 className="upcomingResultsToggle"> {seasonTitle} Season </h2>
                    }
                    {/* Filter Component & Print/Add To calendar Links */}
                    <div className="filterPrintCalContainer">
                        {/* HIDDEN V1 Filter- Filter Componenent Bootstrap Component*/}
                        {/* <Filter />  */}
                        {/* Print and Add to Calendar (ROKT), has conditional so that if the chosen year on dropdown matches the current season we show these options. IF it is a previous legacy year these options will not be showing*/}
                        {year === nbaCurrentSeason &&
                            <div className="printCalContainer">
                                <a className="printSchedule" href="https://s3.grizzliesapp.com/memphis_grizzlies_schedule.pdf" target="_blank" rel="noreferrer">
                                    <h6>  print schedule  </h6>
                                    <img alt="print icon" src={assetsBaseURL + "MG_Icons_Printer.svg"}></img>
                                </a>
                                <a className="addToCalendar" href="https://sync.ecal.com/button/v1/schedule/?widgetId=6514a9a6720de7000db18e3c&apiKey=OdzPd7XMFE2vYnExppDJhda5ZXcEGKZ8pbm5TGk650b85f57ff" target="_blank" rel="noreferrer">
                                    <h6>  sync to calendar </h6>
                                    <img alt="calendar icon" src={assetsBaseURL + "MG_Icons_Calendar_AddTo.svg"}></img>
                                </a>
                            </div>
                        }
                    </div>
                </section>
                {/* Schedule Data Rendered */}
                <section className="globalContainer schedContainer">
                    {filteredData.map((e) => {
                        // Logic block to render season type header only once,
                        // i.e. once we hit e.season type value which is not equal
                        // to seasonTypeCheck (aka prev value)
                        //as per request we removed title for in-season tournament games
                        const printHeader = seasonTypeCheck !== e.seasonType && "in-season" !== e.seasonType
                        if (printHeader) {
                            seasonTypeCheck = e.seasonType
                        }
                        // 50ms = 20 API requests per second (1000ms), i.e. 82 games per season per team = ~ 4 seconds
                        // to fetch max possible games per page, shouldn't hit the limit in this case, may tweak up to
                        // 25ms per request but i had a bunch of 403s when 25ms is set so looks like 50ms is ideal setting
                        fetchDelay += 50
                        return (
                            <React.Fragment key={e.gid}>
                                {printHeader && <h3 id={e.seasonType}>{getSeasonTypeDescription(e.seasonType)}</h3>}
                                <div className={upcomingOrPast ? "futureGameCSS" : "pastGameCSS"}>
                                    {upcomingOrPast
                                        ? <FutureGame {...{ e }} />
                                        : <PastGame {...{ e, fetchDelay }}
                                        />}
                                </div>
                            </React.Fragment>
                        )
                    })}
                    {filteredData.length === 0 && (
                        <div className="noUpcomingGamesFallback">
                            <p> There are currently no upcoming games or events. </p>
                        </div>
                    )}
                </section>
            </main>
        </NBAPageWrapper>
    );
};

export default ScheduleWithContext;
